<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-1">
            <img :src="user.avatar" :alt="user.name" class="avatar" />
          </div>
          <div class="col-md-8">
            <h2 class="mb-0 h3">{{ user.name }}</h2>
            <p v-if="! online">Last Seen: {{ user.last_seen }}</p>
            <p v-if="online">Online</p>
          </div>
          <div class="col-md-3">
            <p class="mb-0">Joined</p>
            <h4 class="h4">{{ user.joined }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Full Profile</h3>
            <table class="table table-responsive table-bordered">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{{ user.role }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{{ user.phone_number }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ user.suspended ? "Suspended" : "Active" }}</td>
                </tr>
                <tr>
                  <td>Telegram Username</td>
                  <td>{{ user.telegram_username }}</td>
                </tr>
              </tbody>
            </table>
            <div class="mt-4">
              <a
                href="#"
                @click.prevent="deleteUser"
                class="btn btn-sm btn-danger"
                >Delete Permanently</a
              >
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Promote to trader</h4>
            <form @submit.prevent="makeTrader" class="mt-2">
              <div class="form-group">
                <label>Activate Community Membership</label>
                <select v-model="community.id" class="form-control">
                  <option v-for="(plan, i) in membership_plans" :key="`plan-${i}`" :value="plan.id">{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-sm">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Signal Subscription Status</h3>
            <table class="table table-responsive table-bordered">
              <tr>
                <td>Expiry</td>
                <td>{{ user.expiry }}</td>
              </tr>
            </table>
            <form @submit.prevent="extendSubscription" class="mt-2">
              <div class="form-group">
                <label>Extend Subscription</label>
                <select v-model="signals.id" class="form-control">
                  <option v-for="(plan, i) in signals_plans" :key="`plan-${i}`" :value="plan.id">{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-sm">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      user: {},
      community: { id: '' },
      signals: { id: '' },
      signals_plans: [],
      membership_plans: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios
        .get(`/api/v1/admin/signal-users/${this.$route.params.id}`)
        .then((response) => {
          this.$loader.stop();
          this.user = response.data.user;
          this.signals_plans = response.data.signals_plans
          this.membership_plans = response.data.membership_plans
        });
    },

    deleteUser() {
      this.$axios.delete(`/api/v1/admin/signal-users/${this.$route.params.id}`).then(() => {
        this.$toasted.show('User has been permanently deleted', { duration: 5000 })
        this.$router.push({ name: 'admin.users.index' })
      })
    },

    extendSubscription() {
      let data = {
        subscription: this.signals.id
      }

      this.$axios.post(`/api/v1/admin/signal-users/${this.$route.params.id}/extend`, data).then(() => {
        this.fetch()
        this.signals.id = ''
      })
    },

    makeTrader() {
      let data = {
        subscription: this.community.id
      }

      this.$axios.post(`/api/v1/admin/signal-users/${this.$route.params.id}/promote`, data).then(() => {
        this.$emit('reload')
      })
    }
  },

  computed: {
    online() {
      return this.$store.getters.getOnlineUsers.filter(user => {
        return this.user.id == user.id
      }).length > 0
    }
  }
};
</script>
