<template>
  <div>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-1">
            <img :src="user.avatar" :alt="user.name" class="avatar" />
          </div>
          <div class="col-md-9">
            <h2 class="mb-0 h3">{{ user.name }}</h2>
            <p v-if="!online">Last Seen: {{ user.last_seen }}</p>
            <p v-if="online">Online</p>
          </div>
          <div class="col-md-2">
            <p class="mb-0">Joined</p>
            <h4 class="h5">{{ user.joined }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Full Profile</h5>
            <table class="table table-responsive-md table-bordered">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{{ user.role }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{{ user.phone_number }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ user.suspended ? "Suspended" : "Active" }}</td>
                </tr>
                <tr v-if="user.is_learner">
                  <td>Academy Progress</td>
                  <td>{{ user.academy_progress }} weeks</td>
                </tr>
                <tr>
                  <td>Academy Access</td>
                  <td>{{ user.academy_access }} Weeks</td>
                </tr>
              </tbody>
            </table>
            <div class="mt-4">
              <a
                href="#"
                @click.prevent="deleteUser"
                class="btn btn-sm btn-danger"
                >Delete Permanently</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <a
              data-toggle="collapse"
              href="#add-academy-learning-weeks"
              role="button"
              aria-expanded="false"
              aria-controls="add-academy-learning-weeks"
              ><h5>
                Add Academy Learning Weeks
                <i class="lni lni-chevron-down float-right"></i></h5
            ></a>
            <form
              @submit.prevent="addAcademyWeeks"
              class="mt-2 collapse show"
              id="add-academy-learning-weeks"
            >
              <div class="alert alert-info">
                <span v-if="user.academy_access > 0">{{ user.name }} has access to the academy up to week {{ user.academy_access }}</span>
                <span v-else>{{ user.name }} has no access to the academy</span>
              </div>
              <div class="form-group">
                <label>Add Academy Learning Weeks</label>
                <a href="#" class="float-right text-xs" @click.prevent="show_weeks = true"><i class="lni lni-calendar mr-2"></i> View Academy Schedule</a>
                <input type="text" placeholder="Number Of Weeks" v-model="add_academy_weeks" class="form-control">
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary btn-sm">
                  <i class="lni lni-save mr-2"></i> Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <a
              data-toggle="collapse"
              href="#activate-signals-subscription"
              role="button"
              aria-expanded="false"
              aria-controls="activate-signals-subscription"
              ><h5>
                Activate Signal Subscription
                <i class="lni lni-chevron-down float-right"></i></h5
            ></a>
            <form
              @submit.prevent="extendSubscription"
              class="mt-2 collapse"
              id="activate-signals-subscription"
            >
              <div class="form-group">
                <label>Signals Subscription Duration</label>
                <select v-model="signals.id" class="form-control">
                  <option
                    v-for="(plan, i) in signals_plans"
                    :key="`plan-${i}`"
                    :value="plan.id"
                  >
                    {{ plan.months }} Month{{ plan.months == 1 ? "" : "s" }}
                  </option>
                </select>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary btn-sm">
                  <i class="lni lni-save mr-2"></i> Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <a
              data-toggle="collapse"
              href="#promote-to-trader"
              role="button"
              aria-expanded="false"
              aria-controls="promote-to-trader"
              ><h5>
                Promote to trader<i
                  class="lni lni-chevron-down float-right"
                ></i></h5
            ></a>
            <form
              @submit.prevent="makeTrader"
              class="mt-2 collapse"
              id="promote-to-trader"
            >
              <div class="form-group">
                <label>Activate Community Membership</label>
                <select v-model="community.id" class="form-control">
                  <option
                    v-for="(plan, i) in membership_plans"
                    :key="`plan-${i}`"
                    :value="plan.id"
                  >
                    {{ plan.months }} Month{{ plan.months == 1 ? "" : "s" }}
                  </option>
                </select>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary btn-sm">
                  <i class="lni lni-save mr-2"></i> Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <modal :show="show_weeks" @close="show_weeks = false">
      <template slot="header">Academy Schedule</template>
      <ul class="list-unstyled">
        <li v-for="(plan, i) in learner_plans" :key="`learner-${i}`">Week {{ i+1 }} - {{ plan.title }}</li>
      </ul>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      user: {},
      community: { id: "" },
      signals: { id: "" },
      signals_plans: [],
      membership_plans: [],
      add_academy_weeks: 1,
      show_weeks: false,
      learner_plans: []
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios
        .get(`/api/v1/admin/other-users/${this.$route.params.id}`)
        .then((response) => {
          this.$loader.stop();
          this.user = response.data.user;
          this.signals_plans = response.data.signals_plans;
          this.membership_plans = response.data.membership_plans;
        });

      this.$axios.get("/api/v1/admin/learners/create").then((response) => {
        this.learner_plans = response.data.weeks;
      });
    },

    deleteUser() {
      this.$axios
        .delete(`/api/v1/admin/other-users/${this.$route.params.id}`)
        .then(() => {
          this.$toasted.show("User has been permanently deleted", {
            duration: 5000,
          });
          this.$router.push({ name: "admin.users.index" });
        });
    },

    extendSubscription() {
      let data = {
        subscription: this.signals.id,
      };

      this.$axios
        .post(`/api/v1/admin/other-users/${this.$route.params.id}/extend`, data)
        .then(() => {
          this.$emit("reload");
        });
    },

    makeTrader() {
      let data = {
        subscription: this.community.id,
      };

      this.$axios
        .post(
          `/api/v1/admin/other-users/${this.$route.params.id}/promote`,
          data
        )
        .then(() => {
          this.$emit("reload");
        });
    },

    addAcademyWeeks() {
      let data = {
        weeks : this.add_academy_weeks
      }

      this.$axios
        .post(
          `/api/v1/admin/other-users/${this.$route.params.id}/add-weeks`,
          data
        )
        .then(() => {
          this.$emit("reload");
        });
    }
  },

  computed: {
    online() {
      return (
        this.$store.getters.getOnlineUsers.filter((user) => {
          return this.user.id == user.id;
        }).length > 0
      );
    },
  },
};
</script>
